export default function PageNotFound() {
    return (
      <section className="bg-home flex items-center py-5 mt-3">
        <div className="container mx-auto">
          <div className="flex justify-center">
            <div className="lg:w-2/3 md:w-full text-center">
              <div className="text-9xl text-orange-500 font-bold relative mt-8 mb-8">
                <span className="absolute inset-0 opacity-20 blur-lg">404</span>
                404
              </div>
              <div className="uppercase text-4xl">Oh no!</div>
              <div
                className="capitalize mb-4 text-2xl mt-2"
                style={{ color: '#3C4858' }}
              >
                Page Not Found
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-10">
            <div className="w-full text-center">
              <a
                href="/"
                className="bg-primary-blue-600 text-white px-4 py-2 rounded-md cursor-pointer"
              >
                Go To Home
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }